html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  height: 100%;
}

#root > main {
  height: auto !important;
}
